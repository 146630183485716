import React, { useState, useEffect } from "react";

export default function RollingText() {
  const items = [
    "늦은 저녁, 해열제가 필요할 때",
    "배달이 안되는 맛집도",
    "어르신 병원 동행도",
    "지역 중고거래 심부름도",
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
    }, 2000); // Change every 2 seconds

    return () => clearInterval(interval);
  }, [items.length]);

  return (
    <div className="rolling_wrap">
      <ul>
        {items.map((item, index) => {
          let className = "";
          if (index === currentIndex) {
            className = "current";
          } else if (index === (currentIndex + 1) % items.length) {
            className = "next";
          } else if (
            index ===
            (currentIndex - 1 + items.length) % items.length
          ) {
            className = "prev";
          }

          return (
            <li key={index} className={className}>
              {item}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
