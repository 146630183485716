import React from "react";
import video from "../assets/videos/sec01_bg.mp4";
import apple from "../assets/images/ico-apple.svg";
import google from "../assets/images/ico-google.svg";
import sec01 from "../assets/images/img-sec01-app.png";
import { isMobile } from "react-device-detect";

export default function Section01() {
  return (
    <section className="sec01 relative">
      <video autoPlay loop muted playsInline>
        <source src={video} type="video/mp4" />
      </video>
      <div
        className="sec01_inner width140 relative"
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-easing="ease-in-out"
        data-aos-duration="800"
      >
        <div className="left">
          <h1 className="mb40">
            도움이 필요할 땐,
            <br />
            헬퍼잇
          </h1>
          <p className="mb80">바쁜 일상 속에서 사람들의 시간을 공유합니다.</p>
          <div className="btns flex alignC">
            <button
              className="apple_btn flex alignC"
              onClick={() => {
                if (isMobile) {
                  window.open(
                    "itms-apps://apps.apple.com/app/id6462087677",
                    "_blank"
                  );
                } else {
                  window.open(
                    "https://apps.apple.com/kr/app/%ED%97%AC%ED%8D%BC%EC%9E%87-%EB%8F%84%EC%9B%80%EC%9D%B4-%ED%95%84%EC%9A%94%ED%95%A0%EB%95%90-%EB%8F%99%EB%84%A4%EC%9D%B8%EB%A0%A5%EC%B0%BE%EA%B8%B0-%EC%8B%AC%EB%B6%80%EB%A6%84-%EC%BA%90%EB%A6%AC%EC%96%B4/id6462087677",
                    "_blank"
                  );
                }
              }}
            >
              <img src={apple} alt="" />
              App Store
            </button>
            <button
              className="google_btn flex alignC"
              onClick={() => {
                if (isMobile) {
                  window.open(
                    "market://details?id=kr.co.helperits.android",
                    "_blank"
                  );
                } else {
                  window.open(
                    "https://play.google.com/store/apps/details?id=kr.co.helperits.android&pli=1",
                    "_blank"
                  );
                }
              }}
            >
              <img src={google} alt="" />
              Google Play
            </button>
          </div>
        </div>
        <div className="sec01_img">
          <img src={sec01} alt="" />
        </div>
      </div>
    </section>
  );
}
