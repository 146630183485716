import React from "react";
import "../assets/css/service.css";
import AnimatedNumber from "react-animated-number";
import StringUtils from "../utils/StringUtils";
import certificate from "../assets/images/service.png";

export default function Service() {
  return (
    <div id="section-2">
      <div className="section-in">
        <img className={"certificate"} src={certificate} alt="" />
        <div style={{ width: 40 }} />
        <ul>
          <li>
            <h2>누적 심부름 건수</h2>
            <h1>
              <AnimatedNumber
                component={"strong"}
                value={136023}
                duration={1500}
                formatValue={(e) => StringUtils.numberWithCommas(e.toFixed(0))}
              />
            </h1>
            <h3>2021년</h3>
          </li>
          <li>
            <h4>소중한 시간을 어떻게 하면 효율적으로 사용할 수 있을까?</h4>
            <h5>
              헬퍼잇은, 위치기반 기술과 타임 쉐어링으로
              <br />
              고객님의 소중한 시간을 지켜드립니다.
            </h5>
          </li>
        </ul>
      </div>
    </div>
  );
}
