import React from "react";
import icon1 from "../assets/images/ico-sec07-icon1.svg";
import icon2 from "../assets/images/ico-sec07-icon2.svg";
import icon3 from "../assets/images/ico-sec07-icon3.svg";

export default function Section07() {
  return (
    <section className="sec07">
      <div
        className="sec07_inner width140 flex alignC justify"
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-easing="ease-in-out"
        data-aos-duration="800"
      >
        <div className="left">
          <h1 className="gra_txt">헬퍼잇의 눈부신 성장</h1>
        </div>
        <div className="right">
          <div className="icon_wrap flex alignC mb100">
            <img src={icon1} alt="" />
            <div className="icon_txt_wrap">
              <p className="mb40">헬퍼잇 앱 출시 3개월</p>
              <h3>1만 3천건 +</h3>
            </div>
          </div>
          <div className="icon_wrap flex alignC mb100">
            <img src={icon2} alt="" />
            <div className="icon_txt_wrap">
              <p className="mb40">누적 심부름 수</p>
              <h3>10만건 +</h3>
            </div>
          </div>
          <div className="icon_wrap flex alignC">
            <img src={icon3} alt="" />
            <div className="icon_txt_wrap">
              <p className="mb40">헬퍼 가입 수</p>
              <h3>1만 1천명 +</h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
