import React from "react";
import sec03pc from "../assets/images/img-sec03-app.png";
import sec03m from "../assets/images/img-sec03-app-m.png";
import RollingText from "./RollingText";

export default function Section03() {
  return (
    <section className="sec03 bg">
      <div
        className="sec03_inner width140 relative"
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-easing="ease-in-out"
        data-aos-duration="800"
      >
        <div className="inner_flex flex justify">
          <div className="left">
            <p className="mb40">프리미엄 생활 심부름</p>
            <h1>
              이런것도 가능해?
              <br />
              헬퍼잇은 가능해!
            </h1>
          </div>
          <div className="right">
            <p className="mb40">당신에게 필요한 것이라면 모두 OK!</p>
            <RollingText />
          </div>
        </div>
        <div className="sec03_img">
          <img className="pc_only" src={sec03pc} alt="" />
          <img className="m_only" src={sec03m} alt="" />
        </div>
      </div>
    </section>
  );
}
