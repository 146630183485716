import React, { useEffect } from "react";

import AOS from "aos";
import "aos/dist/aos.css";

import "./assets/css/reset.css";
import "./assets/css/common.css";
import Header from "./component/Header";
import Section01 from "./component/Section01";
import Section02 from "./component/Section02";
import Section03 from "./component/Section03";
import Section04 from "./component/Section04";
import BackToTop from "./component/BackToTop";
import Section05 from "./component/Section05";
import Section06 from "./component/Section06";
import Section07 from "./component/Section07";
import Footer from "./component/Footer";
import Service from "./component/Service";

function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div>
      <Header />
      <Section01 />
      <Service />
      <Section02 />
      <Section03 />
      <Section04 />
      <Section05 />
      <Section06 />
      <Section07 />
      <Footer />
      <BackToTop />
    </div>
  );
}

export default App;
