import React from "react";
import sec06 from "../assets/images/img-sec06-app.png";

export default function Section06() {
  return (
    <section className="sec06 relative">
      <div
        className="sec06_inner width140 relative"
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-easing="ease-in-out"
        data-aos-duration="800"
      >
        <div className="left">
          <p className="mb40">지역 기반 커뮤니티</p>
          <h1 className="s_h1">
            당일 알바가 필요하신가요?
            <br />
            우리 동네 커뮤니티에서 필요한 인력을 구하기!
          </h1>
        </div>
      </div>
      <div className="sec06_img">
        <img src={sec06} alt="" />
      </div>
    </section>
  );
}
