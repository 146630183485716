import React from "react";
import sec05pc from "../assets/images/img-sec05-app.png";
import sec05m from "../assets/images/img-sec05-app-m.png";

export default function Section05() {
  return (
    <section className="sec05 bg">
      <div
        className="sec05_inner width140 relative"
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-easing="ease-in-out"
        data-aos-duration="800"
      >
        <div className="left">
          <p className="mb40">출석체크 이벤트</p>
          <h1 className="s_h1">
            매일매일 포인트가 지급되는
            <br />
            출석체크 이벤트
          </h1>
        </div>
        <div className="sec05_img">
          <img className="pc_only" src={sec05pc} alt="" />
          <img className="m_only" src={sec05m} alt="" />
        </div>
      </div>
    </section>
  );
}
