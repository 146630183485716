import React from "react";
import sec02 from "../assets/images/img-sec02-app.png";

export default function Section02() {
  return (
    <section className="sec02 bg">
      <div
        className="sec02_inner width140 relative"
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-easing="ease-in-out"
        data-aos-duration="800"
      >
        <div className="left">
          <p className="mb40">원하는 시간과 장소에서 내가 선택하는 일</p>
          <h1>프로 N잡러의 시작, 헬퍼</h1>
        </div>
        <div className="sec02_img">
          <img src={sec02} alt="" />
        </div>
      </div>
    </section>
  );
}
