import React from "react";
import sec04 from "../assets/images/img-sec04-app.png";

export default function Section04() {
  return (
    <section className="sec04 bg">
      <div
        className="sec04_inner width140 relative"
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-easing="ease-in-out"
        data-aos-duration="800"
      >
        <div className="left">
          <p className="mb40">챌린지 보상 포인트 제도</p>
          <h1 className="s_h1">
            매주 새로운 챌린지로 재미와
            <br />
            보상을 얻을 수 있는 포인트 제도
          </h1>
        </div>
        <div className="sec04_img">
          <img src={sec04} alt="" />
        </div>
      </div>
    </section>
  );
}
