import React, { useEffect } from "react";

export default function BackToTop() {
  useEffect(() => {
    const btn = document.querySelector("#button");

    window.addEventListener("scroll", function () {
      if (window.scrollY > 300) {
        btn.classList.add("show");
      } else {
        btn.classList.remove("show");
      }
    });

    btn.addEventListener("click", function (e) {
      e.preventDefault();
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    });
  }, []);

  return <a id="button"></a>;
}
