class StringUtils {
  static numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  static checkPhoneValidation(phone) {
    const patternPhone = /01[016789]-[^0][0-9]{2,3}-[0-9]{3,4}/;
    return patternPhone.test(phone);
  }

  static telWithHyphen(telNumber) {
    let tel = telNumber.replace(/[^0-9]/g, "");
    let tmp = "";
    if (tel.length < 4) {
      tmp += tel;
    } else if (tel.length < 8) {
      tmp += tel.substr(0, 3);
      tmp += "-";
      tmp += tel.substr(3);
    } else {
      tmp += tel.substr(0, 3);
      tmp += "-";
      tmp += tel.substr(3, 4);
      tmp += "-";
      tmp += tel.substr(7, 4);
    }
    return tmp;
  }

  static stringToDateFormat(dateString) {
    let date = dateString.replace(/[^0-9]/g, "");
    let tmp = "";
    if (date.length < 5) {
      tmp += date;
    } else if (date.length < 7) {
      tmp += date.substr(0, 4);
      tmp += "-";
      tmp += date.substr(4);
    } else {
      tmp += date.substr(0, 4);
      tmp += "-";
      tmp += date.substr(4, 2);
      tmp += "-";
      tmp += date.substr(6, 2);
    }
    return tmp;
  }
}

export default StringUtils;
