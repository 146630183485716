import React from "react";
import logo from "../assets/images/img-logo.svg";

export default function Header() {
  return (
    <header>
      <div className="header_inner width140 flex justify alignC">
        <div className="logo">
          <a href="/">
            <img src={logo} alt="" />
          </a>
        </div>
        <button
          onClick={() => {
            const mobileType = navigator.userAgent.toLowerCase();
            if (mobileType.indexOf("android") > -1) {
              window.open(
                "market://details?id=kr.co.helperits.android",
                "_blank"
              );
            } else if (
              mobileType.indexOf("iphone") > -1 ||
              mobileType.indexOf("ipad") > -1 ||
              mobileType.indexOf("ipod") > -1
            ) {
              window.open(
                "itms-apps://apps.apple.com/app/id6462087677",
                "_blank"
              );
            } else if (mobileType.indexOf("mac") > -1) {
              window.open(
                "https://apps.apple.com/kr/app/%ED%97%AC%ED%8D%BC%EC%9E%87-%EB%8F%84%EC%9B%80%EC%9D%B4-%ED%95%84%EC%9A%94%ED%95%A0%EB%95%90-%EB%8F%99%EB%84%A4%EC%9D%B8%EB%A0%A5%EC%B0%BE%EA%B8%B0-%EC%8B%AC%EB%B6%80%EB%A6%84-%EC%BA%90%EB%A6%AC%EC%96%B4/id6462087677",
                "_blank"
              );
            } else {
              window.open(
                "https://play.google.com/store/apps/details?id=kr.co.helperits.android&pli=1",
                "_blank"
              );
            }
          }}
        >
          앱 다운로드
        </button>
      </div>
    </header>
  );
}
